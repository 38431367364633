
import { Component, Vue } from "vue-property-decorator";

@Component
export default class WannWo extends Vue {
  private coordinates = {
    lat: 49.78750973777307,
    lng: 8.036047327319576,
  };

  private width = 1000;
  private height = 400;

  private saengerheimText = `Dieses Motto ziert nicht nur die Theke unseres
  Sängerheimes, sondern spiegelt sich auch in dem Umgang unserer
  Vereinsmitglieder untereinander und der Chorarbeit wieder. Das ruhig gelegene
  Vereinsheim bildet den zentralen Dreh- und Angelpunkt unseres Vereinslebens.`;

  private invitationTextA = `Alle Interessierte heißen wir wöchentlich in 
    unserem Vereinsheim willkommen. Kontaktiere uns gerne unter `;
  private contact = `kontakt@mgv1844-flonheim.de`;
  private invitationTextB = ` oder komme direkt zur offenen Singstunde. 
    Momentane Zeiten:`;
  private date = `Montag, 20:00 Uhr bis 22:00 Uhr`;
  private mapText = `Hier findest Du uns:`;

  private map: any;

  private async mounted() {
    this.map = this.$refs.map;
    this.map = await this.map.$mapPromise;
  }

  private resetCoordinates() {
    this.map.panTo(this.coordinates);
  }
}
