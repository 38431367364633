
import { Component, Vue } from "vue-property-decorator";
import CalendarEvent from "@/types/CalendarEvent";

@Component({
  components: {},
})
export default class Events extends Vue {
  private calendarType: string = "month";
  private selectItems: Object[] = [
    {
      text: "Monat",
      value: "month",
    },
    {
      text: "Woche",
      value: "week",
    },
  ];

  private weekdays = [1, 2, 3, 4, 5, 6, 0];
  private focus = "";
  private events: CalendarEvent[] = [];
  private calendar: any = {
    title: "",
  };

  private selectedEvent: CalendarEvent | {} = {};
  private selectedElement: any = {};
  private dialogVisible: boolean = false;

  private async mounted() {
    this.calendar = this.$refs.calendar;
    const response = await this.axios.get(
      `https://www.googleapis.com/calendar/v3/calendars/8dba7efe5d2e10323276d59fedb3f22cc6d80cfeb1ab21462df94b9749757444@group.calendar.google.com/events`,
      {
        params: {
          key: "AIzaSyAAaytVdJsNKTQCX4-VYu7Pa8ujGXk0tt0",
          singleEvents: true,
          orderBy: "startTime",
        },
      }
    );
    this.events = this.parseEvents(response.data.items);
  }

  private parseEvents(googleEvents: any[]): CalendarEvent[] {
    let events: CalendarEvent[] = [];

    googleEvents.forEach((event) => {
      events.push({
        id: event.id,
        name: event.summary,
        start:
          event.start.dateTime?.substring(0, 19).replace("T", " ") ??
          event.start.date,
        end: event.end.dateTime?.substring(0, 19).replace("T", " ") ?? "",
        description: event.description,
        location: event.location ?? "",
        color: "secondary",
      });
    });

    return events;
  }

  private setToday() {
    this.focus = "";
  }

  private previous() {
    this.calendar.prev();
  }

  private next() {
    this.calendar.next();
  }

  showEvent({
    nativeEvent,
    event,
  }: {
    nativeEvent: any;
    event: CalendarEvent;
  }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.dialogVisible = true), 10);
    };
    if (this.dialogVisible) {
      this.dialogVisible = false;
      setTimeout(open, 10);
    } else {
      open();
    }
    nativeEvent.stopPropagation();
  }

  private getTimeString(event: CalendarEvent) {
    if (event.end === "" || event.start === undefined) {
      return "";
    }

    return (
      this.getLocalTime(event.start) + " - " + this.getLocalTime(event.end)
    );
  }

  private getLocalTime(time: string) {
    return time.substring(11, 16) + " Uhr";
  }
}
